<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h5 primary--text">ลูกค้าเพิ่มตัง</div>
      <div style="width: 400px">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อร้าน"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
        ></v-text-field>
      </div>

      <v-spacer></v-spacer>
      <DatePicker
        label="วันเริ่มต้น"
        :value="filter.start_date"
        @onChange="
          (val) => {
            filter.start_date = val;
            getData(true);
          }
        "
        hideDetails
      />
      <DatePicker
        label="วันสิ้นสุด"
        :value="filter.end_date"
        @onChange="
          (val) => {
            filter.end_date = val;
            getData(true);
          }
        "
        hideDetails
      />
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="id"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        <span>{{ phoneNumberFormat(item.phone_number) }}</span>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{ formatDate(item.start_date) }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ formatDate(item.end_date) }}
      </template>
      <template v-slot:[`item.max_bills`]="{ item }">
        {{ item.is_unlimit ? 'Unlimit' : item.max_bills }}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{ item.product_type == '0' ? 'ครั้งเดียว' : '' }}
        {{ item.product_type == '1' ? 'รายเดือน' : '' }}
        {{ item.product_type == '2' ? 'รายปี' : '' }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    loading: true,
    pageCount: 0,
    headers: [
      { text: '#', value: 'index', align: 'center' },
      { text: 'ชื่อร้านค้า', value: 'name', align: 'center' },
      { text: 'แพ็กเกจหลักปัจจุบัน', value: 'package_name', align: 'center' },
      { text: 'แบบ', value: 'product_type', align: 'center' },
      { text: 'ราคา', value: 'product_price', align: 'center' },
      { text: 'วันที่เริ่มต้น', value: 'start_date', align: 'center' },
      { text: 'วันที่สิ้นสุด', value: 'end_date', align: 'center' },
      { text: 'Max Bill', value: 'max_bills', align: 'center' },
      { text: 'จำนวน Bill ที่ใช้ไป ', value: 'used_bills', align: 'center' },
    ],
    list: [],
    filter: {
      item_per_page: 50,
      page: 1,
      start_with: '',
      start_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
      end_date: moment().add(1, 'years').format('YYYY-MM-DD'),
    },
  }),

  created() {
    this.getData();
  },

  methods: {
    edit(item) {
      this.$router.push({
        name: 'backoffice-customer-update',
        params: { id: item._id },
      });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/backend/customer/get_customer`, body)
        .then((res) => {
          this.list = res.result.data;
          console.log(this.list);
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    phoneNumberFormat(val) {
      if (val) {
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    },
  },
};
</script>
